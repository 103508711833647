import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import ScrollIndicator from "../components/ScrollIndicator";

function TermsConditions() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const { t } = useTranslation();

  const currentLanguage = i18n.language;

  return (
    <div className="w-full flex flex-col items-center justify-center py-[50px]">
      <ScrollIndicator />
      <div id="text-box" className="text-center mx-auto mb-10">
        <h1
          className="text-[60px]  leading-[1.3em] text-[#00001c] font-bold tracking-[-0.05em] py-5"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Poppins, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
          }}
        >
          {t("termsHeader")}
        </h1>
      </div>
      <div id="first" className="w-[85%]">
        <p
          className="text-[22px] font-semibold underline my-2 text-start text-[#000024]"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
          }}
        >
          {t("introductionTitle")}
        </p>
        <ol
          className="flex flex-col text-justify mb-2.5 text-[14px] font-normal text-[#000024] px-10 list-decimal w-[95%] gap-2"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
            fontSize: "16px",
          }}
        >
          <li>{t("introductionLineOne")}</li>
          <li>{t("introductionLineTwo")}</li>
          <li>{t("introductionLineThree")}</li>
          <li>{t("introductionLineFour")}</li>
          <li>{t("introductionLineFive")}</li>
        </ol>
      </div>
      <div id="terms" className="w-[85%]">
        <p
          className="text-[22px] font-semibold underline my-2 text-start text-[#000024]"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
            fontSize: "22px",
          }}
        >
          {t("termsTitle")}
        </p>
        <ol
          className="flex flex-col text-justify mb-2.5 text-[14px] font-normal text-[#000024] px-10 list-decimal w-[95%] gap-2"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
            fontSize: "16px",
          }}
        >
          <ul style={{ listStyleType: "disc" }}>
            <li
              className="text-start mb-2.5 text-[15px] font-normal text-[#000024] cursor-text"
              style={{
                fontFamily: `${currentLanguage === "en"
                  ? "Montserrat, Sans-serif"
                  : "Tajawal, Sans-serif"
                  }`,
                fontSize: "16px",
              }}
            >
              {t("termsSubTitle")}
            </li>
          </ul>
          <li>{t("termsLine1")}</li>
          <li>{t("termsLine2")}</li>
          <li>{t("termsLine3")}</li>
          <li>{t("termsLine4")}</li>
          <li>{t("termsLine5")}</li>
          <li>{t("termsLine6")}</li>
          <li>{t("termsLine7")}</li>
          <li>{t("termsLine8")}</li>
          <li>{t("termsLine9")}</li>
          <li>{t("termsLine10")}</li>
          <li>{t("termsLine11")}</li>
          <li>{t("termsLine12")}</li>
          <li>{t("termsLine13")}</li>
          <li>{t("termsLine14")}</li>
          <li>{t("termsLine15")}</li>
          <li>{t("termsLine16")}</li>
          <li>{t("termsLine17")}</li>
          <li>{t("termsLine18")}</li>
          <li>{t("termsLine19")}</li>
          <li>{t("termsLine20")}</li>
          <li>{t("termsLine21")}</li>
          <li>{t("termsLine22")}</li>
          <ol
            className="flex flex-col text-justify mb-2.5 text-[14px] font-normal text-[#000024] px-10 list-disc w-[95%] gap-2"
            style={{
              fontFamily: `${currentLanguage === "en"
                ? "Montserrat, Sans-serif"
                : "Tajawal, Sans-serif"
                }`,
              fontSize: "16px",
            }}
          >
            <li>{t("termsLine23")}</li>
            <li>{t("termsLine24")}</li>
            <li>{t("termsLine25")}</li>
          </ol>

          <li>{t("termsLine26")}</li>
          <li>{t("termsLine27")}</li>
          <li>{t("termsLine29")}</li>
          <ol
            className="flex flex-col text-justify mb-2.5 text-[14px] font-normal text-[#000024] px-10 list-disc w-[95%] gap-2"
            style={{
              fontFamily: `${currentLanguage === "en"
                ? "Montserrat, Sans-serif"
                : "Tajawal, Sans-serif"
                }`,
              fontSize: "16px",
            }}
          >
            <li>{t("termsLine30")}</li>
            <li>{t("termsLine31")}</li>
            <li>{t("termsLine32")}</li>
            <li>{t("termsLine33")}</li>
            <li>{t("termsLine34")}</li>
            <li>{t("termsLine35")}</li>
          </ol>

        </ol>
      </div>
      <div id="second" className="w-[85%]">
        <p
          className="text-[22px] font-semibold underline my-2 text-start text-[#000024]"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
            fontSize: "22px",
          }}
        >
          {t("adtermsTitle")}
        </p>
        <ol
          className="flex flex-col text-justify mb-2.5 text-[14px] font-normal text-[#000024] px-10 list-decimal w-[95%] gap-2"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
            fontSize: "16px",
          }}
        >
          <li>{t("adtermsLine1")}</li>
          <li>{t("adtermsLine2")}</li>
          <li>{t("adtermsLine3")}</li>
          <li>{t("adtermsLine4")}</li>
          <li>{t("adtermsLine5")}</li>
          <li>{t("adtermsLine6")}</li>
          <li>{t("adtermsLine7")}</li>
          <li>{t("adtermsLine8")}</li>
          <li>{t("adtermsLine9")}</li>
          <li>{t("adtermsLine10")}</li>
          <li>{t("adtermsLine11")}</li>
        </ol>
      </div>
      <div id="third" className="w-[85%]">
        <p
          className="text-[22px] font-semibold underline my-2 text-start text-[#000024]"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
            fontSize: "22px",
          }}
        >
          {t("responsibilityTitle")}
        </p>
        <ol
          className="flex flex-col text-justify mb-2.5 text-[14px] font-normal text-[#000024] px-10 list-disc w-[95%] gap-2"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
            fontSize: "16px",
          }}
        >
          <li>{t("responsibilityLine1")}</li>
          <li>{t("responsibilityLine2")}</li>
          <li>{t("responsibilityLine3")}</li>
          <li>{t("responsibilityLine4")}</li>
          <li>{t("responsibilityLine5")}</li>
          <li>{t("responsibilityLine6")}</li>
          <li>{t("responsibilityLine7")}</li>

          <li>
            {t("responsibilityLine8")}
            <ol className=" list-decimal px-10">
              <li>{t("responsibilityLine9")}</li>
              <li>{t("responsibilityLine11")}</li>
              <li>{t("responsibilityLine12")}</li>
              <li>{t("responsibilityLine13")}</li>
            </ol>
          </li>
          <li>{t("responsibilityLine14")}</li>
          <li>{t("responsibilityLine15")}</li>
        </ol>
      </div>
      <div id="fourth" className="w-[85%]">
        <p
          className="text-[22px] font-semibold underline my-2 text-start text-[#000024]"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
            fontSize: "22px",
          }}
        >
          {t("agreementTitle")}
        </p>
        <ol
          className="flex flex-col text-justify mb-2.5 text-[14px] font-normal text-[#000024] px-10 list-decimal w-[95%] gap-2"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
            fontSize: "16px",
          }}
        >
          <li>{t("agreementLine1")}</li>
          <li>{t("agreementLine2")}</li>
          <li>{t("agreementLine3")}</li>
          <li>{t("agreementLine4")}</li>
        </ol>
      </div>


      <div id="fifth" className="w-[85%]">
        <p
          className="text-[22px] font-semibold underline my-2 text-start text-[#000024]"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
            fontSize: "22px",
          }}
        >
          {t("propertyTitle")}
        </p>
        <ol
          className="flex flex-col text-justify mb-2.5 text-[14px] font-normal text-[#000024] px-10 list-decimal w-[95%] gap-2"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
            fontSize: "16px",
          }}
        >
          <li>{t("propertyLine1")}</li>
          <li>{t("propertyLine3")}</li>
          <li>{t("propertyLine4")}</li>
        </ol>
      </div>
      <div id="sixth" className="w-[85%]">
        <p
          className="text-[22px] font-semibold underline my-2 text-start text-[#000024]"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
            fontSize: "22px",
          }}
        >
          {t("compensationTitle")}
        </p>
        <ol
          className="flex flex-col text-justify mb-2.5 text-[14px] font-normal text-[#000024] px-10 list-decimal w-[95%] gap-2"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
            fontSize: "16px",
          }}
        >
          <li>{t("compensationLine1")}</li>
        </ol>
      </div>
      <div id="seventh" className="w-[85%]">
        <p
          className="text-[22px] font-semibold underline my-2 text-start text-[#000024]"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
            fontSize: "22px",
          }}
        >
          {t("securityTitle")}
        </p>
        <ol
          className="flex flex-col text-start mb-2.5 text-[14px] font-normal text-[#000024] px-10 list-decimal w-[95%] gap-2"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
            fontSize: "16px",
          }}
        >
          <li>{t("securityLine1")}</li>
          <li>{t("securityLine2")}</li>
        </ol>

      </div>


    </div>
  );
}

export default TermsConditions;
