import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";

function SectionThree() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      className="flex flex-col justify-center items-center w-full bg-[#00001C] mx-auto"
      data-aos="fade-up"
      data-aos-duration="900"
      data-aos-once="true"
    >
      <div className="py-[40px] px-5 lg:px-0 lg:py-[60px]">
        <p
          className="text-[36px] lg:text-[48px] font-bold leading-[36px] lg:leading-[1.3em] tracking-tighter text-[#FFF]"
          style={{ fontFamily: "Tajawal, Sans-serif" }}
        >
          {t("sectionThreeTitle")}
        </p>
      </div>
      {/* divider */}
      <div className="w-full bg-[#fffbf9] h-[1px] lg:hidden"></div>
      <div
        className="flex flex-col lg:flex-row items-center gap-7 lg:w-[85%] px-[30px]   lg:px-0 pb-[60px] pt-[30px] lg:pt-0"
        data-aos="fade-up"
        data-aos-duration="900"
        data-aos-once="true"
      >
        <div className="bg-[#F9FAFC] p-[25px] rounded-[11px] self-stretch h-[240px] lg:w-[25%] flex flex-col justify-center items-center transition-all lg:hover:scale-105 duration-200">
          <img
            decoding="async"
            src="https://rize.sa/wp-content/uploads/2023/10/budget.png"
            alt="Comprehensive Listings"
            className="w-[30%] lg:w-[40%]"
          />
          <div className="contents ">
            <h3
              className="text-[22px] font-semibold leading-[1em] m-[10px] tracking-[-1.96px] text-[#445E65]"
              style={{ fontFamily: "Tajawal, Sans-serif" }}
            >
              {t("sectionThreeItemOneTitle")}
            </h3>
            <p
              className="text-[16px] font-normal leading-[1.1em] tracking-[-0.5px] text-[#737588]"
              style={{ fontFamily: "Tajawal, Sans-serif" }}
            >
              {t("sectionThreeItemOneText")}
            </p>
          </div>
        </div>
        <div className="bg-[#F9FAFC] p-[25px] rounded-[11px] self-stretch h-[240px] lg:w-[25%] flex flex-col justify-center items-center transition-all lg:hover:scale-105 duration-200">
          <img
            decoding="async"
            src="https://rize.sa/wp-content/uploads/2023/10/rent.png"
            alt="User-Friendly Interface"
            className="w-[40%]"
          />
          <div className="contents ">
            <h3
              className="text-[22px] font-semibold leading-[1em] m-[10px] tracking-[-1.96px] text-[#445E65]"
              style={{ fontFamily: "Tajawal, Sans-serif" }}
            >
              {t("sectionThreeItemTwoTitle")}
            </h3>
            <p
              className="text-[16px] font-normal leading-[1.1em] tracking-[-0.5px] text-[#737588]"
              style={{ fontFamily: "Tajawal, Sans-serif" }}
            >
              {t("sectionThreeItemTwoText")}
            </p>
          </div>
        </div>
        <div className="bg-[#F9FAFC] p-[25px] rounded-[11px] self-stretch h-[240px] lg:w-[25%] flex flex-col justify-center items-center transition-all lg:hover:scale-105 duration-200">
          <img
            decoding="async"
            src="https://rize.sa/wp-content/uploads/2023/10/wallet.png"
            alt="Detailed Information"
            className="w-[40%]"
          />
          <div className="contents ">
            <h3
              className="text-[22px] font-semibold leading-[1em] m-[10px] tracking-[-1.96px] text-[#445E65]"
              style={{ fontFamily: "Tajawal, Sans-serif" }}
            >
              {t("sectionThreeItemThreeTitle")}
            </h3>
            <p
              className="text-[16px] font-normal leading-[1.1em] tracking-[-0.5px] text-[#737588]"
              style={{ fontFamily: "Tajawal, Sans-serif" }}
            >
              {t("sectionThreeItemThreeText")}
            </p>
          </div>
        </div>
        <div className="bg-[#F9FAFC] p-[25px] rounded-[11px] self-stretch h-[240px] lg:w-[25%] flex flex-col justify-center items-center transition-all lg:hover:scale-105 duration-200">
          <img
            decoding="async"
            src="https://rize.sa/wp-content/uploads/2023/10/application.png"
            alt="Personalized Experience"
            className="w-[40%]"
          />
          <div className="contents ">
            <h3
              className="text-[22px] font-semibold leading-[1em] m-[10px] tracking-[-1.96px] text-[#445E65]"
              style={{ fontFamily: "Tajawal, Sans-serif" }}
            >
              {t("sectionThreeItemFourTitle")}
            </h3>
            <p
              className="text-[16px] font-normal leading-[1.1em] tracking-[-0.5px] text-[#737588]"
              style={{ fontFamily: "Tajawal, Sans-serif" }}
            >
              {t("sectionThreeItemFourText")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionThree;
