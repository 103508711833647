import "./App.css";
import { useEffect, useRef } from "react";
import { Routes, Route, useParams, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import Homepage from "./pages/Homepage/Homepage";
import Navbar from "./components/header/WebNavbar";
import MobileNavbar from "./components/header/MobileNavbar";
import Footer from "./components/footer/Footer";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactForm from "./pages/ContactForm";

function App() {
  const targetRef = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lng } = useParams(); // Get language from the URL params
  const location = useLocation(); // To get the current location/path of the URL

  const scrollToTarget = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  // Sync i18n language with the URL language
  useEffect(() => {
    if (lng && i18n.language !== lng) {
      i18n.changeLanguage(lng); // Set the language in i18n
      document.documentElement.dir = lng === "ar" ? "rtl" : "ltr"; // Set text direction
    }
  }, [lng]);

  const currentLanguage = i18n.language; // Get the current language from i18n instance

  // Set root element's direction based on language
  document.documentElement.dir = currentLanguage === "ar" ? "rtl" : "ltr";

  // Function to set language preference in local storage
  const setLanguagePreference = (lng) => {
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng); // Change i18n language
    navigate(`/${lng}`); // Update the URL with the language
  };

  // Redirect logic for iOS device detection, applied only for PropertyDetailsPage URLs
  useEffect(() => {
    const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isPropertyDetailsPage = location.pathname.includes('PropertyDetailsPage');
    const isMobile = /Mobi|Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(navigator.userAgent);


    // If the current path includes PropertyDetailsPage and the device is iOS
    if (isPropertyDetailsPage && isMobile) {
      const propertyId = new URLSearchParams(location.search).get('propertyId');
      const baseUrl = `https://somer.iq/PropertyDetailsPage?propertyId=${propertyId}`;
      const iosUrl = `somer.iq://somer.iq/PropertyDetailsPage?propertyId=${propertyId}`;

      if (isIos) {
        window.location.href = iosUrl;
      } else {
        window.location.href = baseUrl;
      }
    }
  }, [location]); // Dependency on location to check when the route changes

  return (
    <div className="App">
      <Navbar
        scrollToTarget={scrollToTarget}
        setLanguagePreference={setLanguagePreference}
      />
      <MobileNavbar setLanguagePreference={setLanguagePreference} />
      <Routes>
        <Route path="/:lng" element={<Homepage targetRef={targetRef} />} />
        <Route path="/:lng/terms-conditions" element={<TermsConditions />} />
        <Route path="/:lng/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/:lng/contact-us" element={<ContactForm />} />
        <Route path="*" element={<Homepage targetRef={targetRef} />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
