// ScrollIndicator.js

import React, { useState, useEffect } from "react";
import i18n from "../i18n";

const ScrollIndicator = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const currentLanguage = i18n.language;

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      const windowHeight = window.innerHeight;
      const bodyHeight = document.body.scrollHeight - windowHeight;
      const scrollPercentage = (position / bodyHeight) * 100;
      setScrollPosition(scrollPercentage);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`fixed top-0 ${
        currentLanguage === "en" ? "right-8" : "left-8"
      }  h-full lg:flex flex-col justify-center items-center z-50 gap-4 hidden`}
    >
      <p
        className="text-[13px] font-medium tracking-[0.01em] text-black leading-[22px] -rotate-90"
        style={{ fontFamily: "Chivo, Sans-serif" }}
      >
        scroll
      </p>
      <div className="relative w-[1px] h-[60px]">
        <div className="w-[1px] bg-gray-500 absolute top-0 left-1/2 transform -translate-x-1/2 h-full"></div>
        <div
          className="bg-black w-2 h-2 rounded-full absolute top-0 left-1/2 transform -translate-x-1/2"
          style={{ top: `${scrollPosition}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ScrollIndicator;
