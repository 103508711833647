import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import ScrollIndicator from "../components/ScrollIndicator";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const { t } = useTranslation();

  const currentLanguage = i18n.language;

  return (
    <div className="w-full flex flex-col items-center justify-center py-[50px]">
      <ScrollIndicator />
      <div id="text-box" className="text-center mx-auto mb-10">
        <h1
          className="text-[60px]  leading-[1.3em] text-[#00001c] font-bold tracking-[-0.05em] py-5"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Poppins, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
          }}
        >
          {t("privacyHeader")}
        </h1>
      </div>
      <div id="first" className="w-[85%]">
        <p
          className="text-[22px] font-medium mb-2 text-start text-[#000024]"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
          }}
        >
          {t("policyTitle")}
        </p>
        <ol
          className="flex flex-col text-justify mb-2.5 text-[14px] font-normal text-[#000024] px-10 list-disc w-[95%] gap-2"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
            fontSize: "16px",
          }}
        >
          <li>{t("policyLine1")}</li>
          <li>{t("policyLine2")}</li>
        </ol>
      </div>
      <div id="collect" className="w-[85%]">
        <p
          className="text-[18px] font-medium mb-2 text-start text-[#000024]"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
          }}
        >
          {t("collectpolicy")}
        </p>
        <p
          className="text-[18px] font-normal mb-2 text-start text-[#000024]"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
          }}
        >
          {t("datacollectionsubtitle")}
        </p>
        <ol
          className="flex flex-col text-justify mb-2.5 text-[14px] font-normal text-[#000024] px-10 list-decimal w-[95%] gap-2"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
            fontSize: "16px",
          }}
        >
          <li>{t("collectpolicy1")}</li>
          <li>{t("collectpolicy3")}</li>
          <li>{t("collectpolicy4")}</li>
        </ol>
      </div>
      <div id="info" className="w-[85%]">
        <p
          className="text-[18px] font-medium mb-2 text-start text-[#000024]"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
          }}
        >
          {t("infopolicy")}
        </p>
        <p
          className="text-[18px] font-normal mb-2 text-start text-[#000024]"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
          }}
        >
          {t("whywecollectpolicy")}
        </p>
        <ol
          className="flex flex-col text-justify mb-2.5 text-[14px] font-normal text-[#000024] px-10 list-decimal w-[95%] gap-2"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
            fontSize: "16px",
          }}
        >
          <li>{t("infopolicy1")}</li>
          <li>{t("infopolicy2")}</li>
          <li>{t("infopolicy3")}</li>
          <li>{t("infopolicy4")}</li>
          <li>{t("infopolicy5")}</li>
        </ol>
      </div>

      <div id="third" className="w-[85%]">
        <p
          className="text-[22px] font-medium mb-2 text-start text-[#000024]"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
          }}
        >
          {t("sharepropertywithoutside")}
        </p>
        <p
          className="text-[18px] font-normal mb-2 text-start text-[#000024]"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
          }}
        >
          {t("datamaybesharedwithoutside")}
        </p>
        <ol
          className="flex flex-col text-justify mb-2.5 text-[14px] font-normal text-[#000024] px-10 list-decimal w-[95%] gap-2"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
            fontSize: "16px",
          }}
        >
          <li>{t("sharingdata1")}</li>
          <li>{t("sharingdata3")}</li>

        </ol>

      </div>
      <div id="fourth" className="w-[85%]">
        <p
          className="text-[22px] font-medium mb-2 text-start text-[#000024]"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
          }}
        >
          {t("securitypolicy")}
        </p>
        <ol
          className="flex flex-col text-justify mb-2.5 text-[14px] font-normal text-[#000024] px-10  w-[95%] gap-2"
          style={{
            fontFamily: `${currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
              }`,
            fontSize: "16px",
          }}
        >
          <li>{t("securitypolicy1")}</li>
        </ol>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
