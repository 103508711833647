import React, { useEffect } from "react";
import SectionOne from "../../components/sections/SectionOne";
import SectionTwo from "../../components/sections/SectionTwo";
import SectionThree from "../../components/sections/SectionThree";
import SectionFour from "../../components/sections/SectionFour";
import SectionFive from "../../components/sections/SectionFive";
import ScrollIndicator from "../../components/ScrollIndicator";

function Homepage({ targetRef }) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="w-full bg-[#fffdfc] relative">
      <ScrollIndicator />
      <div className="flex flex-col justify-center items-center w-full gap-5 lg:gap-10 mb-20 pt-20">
        <SectionFour />
        <SectionOne />
        <SectionTwo />
        <SectionThree />
        <SectionFive />
      </div>
    </div>
  );
}

export default Homepage;
